<template>
  <div>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Login v1 -->

        <div
          v-if="confirm"
          class="text-center"
        >
          <div class="loaderer-container">
            <div class="loaderer">
              <div class="inner-circle" />
            </div>
          </div>
          <!-- <img
            src="/logo/logo.png"
            alt="logo"
          >
          <div class="d-flex align-items-center justify-content-center">
            <b-spinner
              small
              label="Spinning"
              class="text-pink"
            /> <small class="text-pink">&nbsp;Loading...</small>
          </div> -->
        </div>

        <div
          v-if="!confirm"
          class="login"
        >
          <validation-observer ref="loginForm">
            <b-form
              class="auth-login-form p-1"
              @submit.prevent="login"
            >
              <!-- email -->
              <!-- <b-form-group>
                <label for="username">ชื่อผู้ใช้งาน หรือ เบอร์โทรศัพท์</label>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label
                    for="login-password"
                  >กรุณากรอกรหัสผ่าน VIP ของท่าน</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder=""
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? "กรอกรหัสผ่านให้ถูกต้อง" : ""
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  <span class="text-white">จดจำฉันไว้ในระบบ</span>
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                class="btn-gradient-primary"
                block
                @click="CheckPass()"
              >
                เข้าสู่ระบบ
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->
        </div>
        <!-- /Login v1 -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  // BImg,
  // BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  // BFormCheckbox,
  BCardText,
  // BCard,
  // BCardTitle,
  BForm,
  BButton,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // BCard,
    // BImg,
    // BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    // BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      confirm: true,
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      LottoList: [{ text: 'กรุณาเลือกหวย', value: null, SubList: null }],
      // THG :ผลสลากกินแบ่งรัฐบาล , GSB : ออมสิน, BAAC : ธกส,
      // STH : หุ้นไทย, HN : หวยฮานอย,
      // HNRB : หวยฮานอย แดงดำ, VT : หวยเวียดนาม, ML : หวยมาเลย์ (Magnum4D)
      // LottoSubHead:String,
      // STH = STH1 : หวยหุ้นไทย (เที่ยง), STH2 : หวยหุ้นไทย (บ่าย]), STH3 : หวยหุ้นไทย (เย็น),
      // HN = HNST : หวยฮานอย, HNSP : ฮานอยพิเศษ, HNVIP : ฮานอย วีไอพี Hanoi Vip
      // HNRB = HNR : ฮานอย แดง, HNB : ฮานอย ดำ,
      // VT = VTST : เวียดนาม, VTHCM : โฮจิมินห์ Ho Chi Minh,  VTVIP : เวียดนาม VIP
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  async mounted() {
    this.Login()
  },
  methods: {
    // CheckPass() {
    //   if (btoa(this.password) === 'UEBwb3dlcjEwMA==') {
    //     this.confirm = true
    //     this.Login()
    //   }
    // },
    async Login() {
      try {
        let Token = null
        const StToken = localStorage.getItem('BreakToken')
        if (this.$route.query.token) {
          Token = this.$route.query.token
          localStorage.setItem('BreakToken', btoa(this.$route.query.token))
        } else if (StToken) {
          Token = atob(StToken)
        }

        const obj = {
          AgToken: Token,
        }
        // console.log(obj)
        const { data: ResData } = await this.$http.post(
          '/seamless/login-next',
          obj,
        )

        if (ResData) {
          useJwt.setToken(ResData.token)
          useJwt.setRefreshToken(ResData.refreshToken)
          localStorage.setItem('userData', JSON.stringify(ResData))
          localStorage.setItem('UserToken', ResData.UserToken)
          this.$ability.update(ResData.ability)

          this.$router
            .replace(getHomeRouteForLoggedInUser(ResData.role))
            .then(() => {
              // this.$toast({
              //   component: ToastificationContent,
              //   position: 'top-right',
              //   props: {
              //     title: 'ยินดีต้อนรับค่ะ',
              //     icon: 'CoffeeIcon',
              //     variant: 'success',
              //     text: '',
              //   },
              // })
            })
        }
      } catch (e) {
        console.log(e)
        this.$bvToast.toast('เข้าสู้ระบบไม่สำเร็จ', {
          title: 'ล็อกอินไม่สำเร็จ',
          variant: 'danger',
          solid: true,
        })
        this.$refs.loginForm.setErrors(e.response.data.error)
      }
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then(response => {
              const userData = response.data
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              localStorage.setItem('LottoList', JSON.stringify(this.LottoList))
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router
                .push(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Welcome to Image Dashboard',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'You have successfully logged ',
                    },
                  })
                })
                .catch(error => {
                  this.$refs.loginForm.setErrors(error.response.data.error)
                })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ERROR Email or Password incorrect',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error,
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style scoped>

.loaderer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loaderer {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(0deg, #FFFFFF 50%, #105FA6 100%);
  animation: spin 1s linear infinite;
}

.inner-circle {
  width: 90%;
  height: 90%;
  background-color: white;
  border-radius: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(360deg);
  }
}
.bg-whiter {
  background-color: #FFFFFF;
}
label {
  color: #fff;
}

.btn-gradient-primary {
  background: linear-gradient(87deg, #590094 0, #b56bff 100%);
  border: 1px solid #7367f0 !important;
  color: #fff;
}

.multi-spinner-container {
  width: 150px;
  height: 150px;
  position: relative;
  margin: 30px auto;
  overflow: hidden;
}

.multi-spinner {
  position: absolute;
  width: calc(100% - 9.9px);
  height: calc(100% - 9.9px);
  border: 5px solid transparent;
  border-top-color: #64c6ff;
  border-radius: 50%;
  -webkit-animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
  animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
